import Vue from 'vue';
import axios from 'axios';
import authenticationService from "./Services/Authentication";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import App from './App.vue';
import router from "./Router";
import moment from 'moment';
import {ModelSelect} from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';
import Highcharts from 'highcharts';
import Stock from "highcharts/modules/stock";
import HighchartsVue from 'highcharts-vue';
//import VueDarkMode from "@growthbunker/vuedarkmode";
import VueApexCharts from 'vue-apexcharts';
import {ClientTable, ServerTable} from 'vue-tables-2';
import JsonCSV from 'vue-json-csv';
import JsonExcel from "vue-json-excel";
//import VueSocketIO from "vue-socket.io";
//import socketClient from 'socket.io-client';
//import {req} from "vuelidate/lib/validators/common";
import 'es6-promise/auto'
import store from './Store'

import 'devextreme/dist/css/dx.light.css';
import Vuelidate from "vuelidate"


Highcharts.setOptions({
  lang: {
    thousandsSep: ","
  }
});

let user = authenticationService.currentUserValue;


if (user) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token || '';
/*  axios.interceptors.request.use((request)=>{
    console.log(request.data);
    console.log(request.method);
    console.log(request.url);
    console.log(user.id)
    return request;
  })*/
}
axios.interceptors.response.use(function (response) {
  if(response.status===500){
   ElementUI.Message({type:"error", message:"დაფიქსსირდა შეცდომა"}); 
  }
  return response;
},  error => {
  let msg=error.response.data
  if(error.response.status===500){
   ElementUI.Message({type:"error", message:msg, duration:6000, showClose:true}); 
  }
  if (error.response.status === 401) {
    if(authenticationService.currentUserValue){
      localStorage.removeItem('currentUser');
    }
      location.replace("/login")
  }
  return Promise.reject(error);
});


/*Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(value).format('DD/MM/yyyy HH:mm:ss')
  }
});*/

const mixin = {

  methods:{
    formatDate: function (value) {
      if (value) {
        return moment(value).format('yyyy-MM-DD')
      }
    }
  }
  
}

Stock(Highcharts);

Vue.config.productionTip = false
Vue.use(ElementUI,{locale});
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(ClientTable);
Vue.use(ServerTable);
Vue.use(HighchartsVue);
Vue.use(Vuelidate);


Vue.mixin(mixin)

/*Vue.use(new VueSocketIO({
  debug: true,
  connection: socketClient('https://socket.ardi.ge')
}));*/

Vue.component('downloadCsv', JsonCSV);
Vue.component("downloadExcel", JsonExcel);
Vue.component('apexChart', VueApexCharts);
Vue.component('ModelSelect', ModelSelect);
Vue.component('highCharts', Highcharts);

//Vue.use(VueDarkMode);

axios.defaults.baseURL = 'https://directapi.ardi.ge/'
//axios.defaults.baseURL = 'http://localhost:5000/'
//axios.defaults.baseURL = 'https://test5.ardi.ge/'
Vue.prototype.$http = axios;

new Vue({
  router,
  /*mixins: [mixin],*/
  render: h  => h(App),
  store
}).$mount('#app')
