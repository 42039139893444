import Vue from 'vue';
import Router from 'vue-router';

import {authenticationService} from './Services/Authentication'

//main pages
const Home = () => import('./Pages/Home');
//const Dashboard = () => import('./Pages/Components/Dashboard');

const Login = () => import('./Pages/Login');

// Clinic pages
const Appeal = () => import('./Pages/Clinic/Appeal');
const Invoices = () => import('./Pages/Clinic/Invoice');
const Notify = () => import('./Pages/Clinic/Notify');
const FileUpload = () => import('./Pages/Clinic/FileUpload');
const ClinicServices = () => import('./Pages/Clinic/ClinicServices');

// View - Adjustment Components
const AdjustmentInvoice = () => import ('./Pages/Adjustment/Invoice');
const AdjustmentProvidersPrices = () => import ('./Pages/Adjustment/ProvidersPrices');

//Request payment and referrals
const ReferralRequest = () => import('./Pages/Requests/ReferralStatement')
const PaymentRequest = () => import('./Pages/Requests/RefundStatement')

/*
import  customers from './Pages/Customers/route'
console.log(customers.filter(r=> {return r}))*/


Vue.use(Router);

const router = new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({y: 0}),
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            children: [
                {
                    path: '/',
                    name: 'Dashboard',
                   // component: Dashboard,
                    meta: {authorize: []},
                },
                {
                    path: 'appeal',
                    name: 'Appeal',
                    component: Appeal,
                    meta: {authorize: ["Admin", "Referral"]},
                },
                {
                    path: 'notify',
                    name: 'Notify',
                    component: Notify,
                    meta: {authorize: ["Admin", "Referral"]},
                },
                {
                    path: 'invoice',
                    name: 'Invoice',
                    component: Invoices,
                    meta: {authorize: ["Admin", "Invoice"]},

                },
                {
                    path: 'fileUpload',
                    name: 'FileUpload',
                    component: FileUpload,
                    meta: {authorize: ["Admin", "FileUpload"]},

                },
                {
                    path: 'clinicServices',
                    name: 'ClinicServices',
                    component: ClinicServices,
                    meta: {authorize: ["Admin", "ClinicServices"]},

                },
                {
                    path: 'Adjustment/invoice',
                    name: 'Invoice',
                    component: AdjustmentInvoice,
                    meta: {authorize: ["Admin", "Regulation"]},

                },
                {
                    path: 'Adjustment/ProvidersPrices',
                    name: 'ProvidersPrices',
                    component: AdjustmentProvidersPrices,
                    meta: {authorize: ["Admin", "Regulation"]},

                },
                {
                    path: 'Adjustment/Contacts',
                    name: 'Contacts',
                    component: () => import('./Pages/Adjustment/Contact'),
                    meta: {authorize: ["Admin", "Regulation"]},

                },
                {
                    path: 'Requests/Referrals',
                    name: 'ReferralStatement',
                    component: ReferralRequest,
                    meta: {authorize: ["Admin", "Requests"]},

                },
                {
                    path: 'Requests/Payment',
                    name: 'RefundStatement',
                    component: PaymentRequest,
                    meta: {authorize: ["Admin", "Requests"]},

                },
                {
                    path: 'Requests/AllStatement',
                    name: 'ReferralsAndRefund',
                    component: () => import('./Pages/Requests/ReferralsAndRefund.vue'),
                    meta: {authorize: ["Admin", "RequestsManger", "StatementView"]},

                }, 
                {
                    path: 'Requests/VipStatement',
                    name: 'ReferralsAndRefundVip',
                    component: () => import('./Pages/Requests/ReferralsAndRefundForVip'),
                    meta: {authorize: ["Admin", "VipManagerStatement"]},

                },
                {
                    path: 'Requests/VipAssistantStatement',
                    name: 'ReferralsAndRefundVipAssistant',
                    component: () => import('./Pages/Requests/ReferralsAndRefundForVipAssistant'),
                    meta: {authorize: ["Admin", "VipAssistantStatement"]},

                },
                {
                    path: 'Requests/StatementRegistration',
                    name: 'StatementRegistration',
                    component: () => import('./Pages/Requests/StatementRegistration'),
                    meta: {authorize: ["Admin", "StatementRegistration", "StatementView"]},

                },
                {
                    path: 'Requests/ClinicStatementRegistration',
                    name: 'ClinicStatementRegistration',
                    component: () => import('./Pages/Requests/ClinicStatements'),
                    meta: {authorize: ["Admin", "Referral" ]},

                },
                {
                    path: 'Requests/Managers',
                    name: 'RequestManagers',
                    component: () => import('./Pages/Components/Managers'),
                    meta: {authorize: ['Admin', 'RequestsManger']}
                },
                {
                    path: 'call/SmsSend',
                    name: 'SmsSend',
                    component: () => import('./Pages/CallCenter/SendSms'),
                    meta: {authorize: ["Admin", "Call"]},

                },
                {
                    path: 'call/SmsReport',
                    name: 'SmsReport',
                    component: () => import('./Pages/CallCenter/Report'),
                    meta: {authorize: ["Admin", "Call"]},

                },
                {
                    path: 'admin/register',
                    name: 'Register',
                    component: () => import('./Pages/Admin/Register'),
                    meta: {authorize: ["Admin", "UserManager"]},

                },
                {
                    path: 'claim',
                    name: 'Claim',
                    component: () => import('./Pages/Claim/Dashboard'),
                    meta: {authorize: ["Admin", "ClaimManager"]},
                },
                {
                    path: 'admin/Users',
                    name: 'Users',
                    component: () => import('./Pages/Admin/Users'),
                    meta: {authorize: ["Admin", "UserManager"]},

                },
                {
                    path: 'claim/cases',
                    name: 'claimCases',
                    component: () => import('./Pages/Claim/Claim'),
                    meta: {authorize: ["Admin", "ClaimManager"]},
                },
                {
                    path: 'claim/regulation',
                    name: 'claimRegulation',
                    component: () => import('./Pages/Claim/Regulation'),
                    meta: {authorize: ["Admin", "ClaimRegulation"]},
                },
                {
                    path: 'claim/regress',
                    name: 'claimRegress',
                    component: () => import('./Pages/Claim/RegressView'),
                    meta: {authorize: ["Admin", "Regress"]},
                },
                {
                    path: 'claim/regress',
                    name: 'claimRegress',
                    component: () => import('./Pages/Claim/RegressView'),
                    meta: {authorize: ["Admin", "Regress"]},
                },
                {
                    path: 'claim/regress/:id',
                    name: 'claimRegressById',
                    component: () => import('./Pages/Claim/RegressView'),
                    meta: {authorize: ["Admin", "Regress"]},
                },
                {
                    path: 'claim/AutoProlongation',
                    name: 'claimAutoProlongation',
                    component: () => import('./Pages/Claim/AutoProlongation'),
                    meta: {authorize: ["Admin", "AutoProlongationManager", "AutoProlongation"]},
                },
                {
                    path: 'AutoProlongation',
                    name: 'AutoProlongation',
                    component: () => import('./Pages/AutoProlongation/AutoProlongationCases'),
                    meta: {authorize: ["Admin", "AutoProlongation", "AutoProlongation"]},
                },
                {
                    path: 'AutoProlongation/Cases',
                    name: 'AutoProlongation',
                    component: () => import('./Pages/AutoProlongation/Cases'),
                    meta: {authorize: ["Admin", "AutoProlongationManager", "AutoProlongation"]},
                },
                {
                    path: 'AutoProlongation/Offer',
                    name: 'AutoProlongationOffer',
                    component: () => import('./Pages/AutoProlongation/Offer'),
                    meta: {authorize: ["Admin", "AutoProlongationManager", "AutoProlongation"]},
                },
                {
                    path: 'AutoProlongation/Vit',
                    name: 'AutoProlongationVitamin',
                    component: () => import('./Pages/AutoProlongation/AutoProlongationVitamin'),
                    /*meta: { authorize: ["Admin", "VitProlongationManager", "VitProlongationAdmin","VitProlongationSuperAdmin"] },*/
                },
                
                {
                    path: 'report',
                    name: '',
                    component: () => import('./Pages/Report/Dashboard'),
                    meta: {authorize: ["Admin", "Reports"]},
                },
                {
                    path: 'Report/Covid',
                    name: '',
                    component: () => import('./Pages/Report/Covid'),
                    meta: {authorize: ["Admin", "CovidReport"]},
                },
                {
                    path: 'Report/Statistic',
                    name: '',
                    component: () => import('./Pages/Report/Statistic'),
                    meta: {authorize: ["Admin", "Requests", "Regulation", "RequestsManger"]},
                },
                {
                    path: 'operating/signature',
                    name: '',
                    component: () => import('./Pages/Operating/Signature'),
                    meta: {authorize: ["Admin", "Signature"]},
                },
                {
                    path: 'Report/Esign',
                    name: '',
                    component: () => import('./Pages/Report/Esign'),
                    meta: {authorize: ["Admin", "Esign"]},
                },
                {
                    path: 'Covid/Dashboard',
                    name: 'CovidCases',
                    component: () => import('./Pages/Covid/Dashboard'),
                    meta: {authorize: ["Admin", "CovidManager"]},
                },
                {
                    path: 'Covid/Dashboard/:id',
                    name: 'SingleCovidCases',
                    component: () => import('./Pages/Covid/Dashboard'),
                    meta: {authorize: ["Admin", "CovidManager"]},
                },
                {
                    path: 'Covid/DoctorDashboard',
                    name: 'DoctorCovidCases',
                    component: () => import('./Pages/Covid/DoctorDashboard'),
                    meta: {authorize: ["Admin", "CovidDoctor", "Assistant"]},
                },
                {
                    path: 'Covid/DoctorDashboard/:id',
                    name: 'DoctorSingleCovidCases',
                    component: () => import('./Pages/Covid/DoctorDashboard'),
                    meta: {authorize: ["Admin", "CovidDoctor", "Assistant"]},
                },
                {
                    path: 'Calendar',
                    name: 'Calendar',
                    component: () => import('./Pages/Components/Calendar'),
                    meta: {authorize: ["Admin", "CovidDoctor"]},
                },
                {
                    path: 'Call/Pcr',
                    name: 'PcrForm',
                    component: () => import('./Pages/CallCenter/PcrStatement'),
                    meta: {authorize: ["Admin", "Call", "PCR"]},
                },
                {
                    path: 'Call/Pcr/:id',
                    name: 'PcrFormById',
                    component: () => import('./Pages/CallCenter/PcrStatement'),
                    meta: {authorize: ["Admin", "Call", "PCR"]},
                },
                {
                    path: 'covid/Pcr',
                    name: 'PcrCases',
                    component: () => import('./Pages/Covid/PcrCases'),
                    meta: {authorize: ["Admin", "CovidPcr", "PCR"]},
                },
                {
                    path: 'covid/Pcr/:id',
                    name: 'PcrCasesById',
                    component: () => import('./Pages/Covid/PcrCases'),
                    meta: {authorize: ["Admin", "CovidPcr", "PCR"]},
                },
                {
                    path: 'covid/Booking',
                    name: 'BookingCovidCases',
                    component: () => import('./Pages/Covid/Booking'),
                    meta: {authorize: ["Admin", "Booking"]},
                },
                {
                    path: 'covid/Booking/:id',
                    name: 'BookingSingleCovidCases',
                    component: () => import('./Pages/Covid/Booking'),
                    meta: {authorize: ["Admin", "Booking"]},
                },
                {
                    path: 'Operating/PrintPolicy',
                    name: 'PrintPolicy',
                    component: () => import('./Pages/Operating/PrintPolicyCard'),
                    meta: {authorize: ["Admin", "Operating", "PrintPolicy"]},
                },
                {
                    path: 'support/bog',
                    name: 'PrintPolicy',
                    component: () => import('./Pages/Support/Bog'),
                    meta: {authorize: ["Admin", "Support"]},
                },
                {
                    path: 'administration/Contracts',
                    name: 'Contracts',
                    component: () => import('./Pages/Administration/Contracts'),
                    meta: {authorize: ["Admin", "Contracts"]},
                },
                {
                    path: 'administration/MeetingRooms',
                    name: 'Meeting Rooms',
                    component: () => import('./Pages/Administration/Meetings'),
                    meta: {authorize: ["Admin"]},
                },
                {
                    path: 'administration/display',
                    name: 'Contracts',
                    component: () => import('./Pages/Administration/Monitors'),
                    meta: {authorize: ["Admin", "Display"]},
                },
                {
                    path: 'administration/meetingsTable',
                    name: 'meetingsTable',
                    component: () => import('./Pages/Administration/Components/MeetingsTable.vue'),
                    meta: {authorize: ["Admin", "CampusMeeting", "MeetingManager"]},

                },
                {
                    path: 'forwarding/registerclient',
                    name: 'registerclient',
                    component: () => import('./Pages/Forwarding/RegisterClient'),
                    meta: {authorize: ["Admin", "ForwardingLeadRegistration"]},
                },
                {
                    path: 'forwarding/managerlead',
                    name: 'managerlead',
                    component: () => import('./Pages/Forwarding/ManagerLead'),
                    meta: {authorize: ["Admin", "ForwardingUrgentLead", "ForwardingCross", "ForwardingAdmin"]},
                },
                {
                    path: 'blackList',
                    name: 'blackList',
                    component: () => import('./Pages/Customers/BlackList'),
                    meta: {authorize: ["Admin", "BlackList", "BlackListView"]},

                },
                {
                    path: 'administration/meetingsTable',
                    name: 'meetingsTable',
                    component: () => import('./Pages/Administration/Components/MeetingsTable.vue'),
                    meta: {authorize: ["Admin", "BlackList", "BlackListView"]},

                },
                {
                    path: 'Crm/CorporateClient',
                    name: 'CorporateClient',
                    component: () => import('./Pages/Crm/CorporateClient'),
                    meta: { authorize: ["Admin","PotentialClientsCorporate"] },
                },
                {
                    path: 'Crm/CorporateClientDashBoard',
                    name: 'CorporateClientDashBoard',
                    component: () => import('./Pages/Crm/CorporateClientDashBoardTable'),
                    meta: { authorize: ["Admin","PotentialClientsCorporateDashboard"]},
                },
                {
                    path: 'Crm/MyCorporateClient',
                    name: 'MyCorporateClient',
                    component: () => import('./Pages/Crm/MyCorporateClientTable'),
                    meta: { authorize: ["Admin","MyPotentialClientsCorporate"] },
                },
                {
                    path: 'Crm/PotencialClientRetails',
                    name: 'PotencialClientRetails',
                    component: () => import('./Pages/Crm/PotencialClientRetails'),
                    meta: { authorize: ["Admin", "PotencialClientRetails"] },
                    
                },
                {
                    path: 'Crm/MyPotencialClientRetails',
                    name: 'MyPotencialClientRetails',
                    component: () => import('./Pages/Crm/MyPotencialClientRetails'),
                    meta: { authorize: ["Admin", "MyPotencialClientRetails"] },

                },
                {
                    path: 'Crm/Underwriting',
                    name: 'Underwriting',
                    component: () => import('./Pages/Crm/Underwriting'),
                    meta: { authorize: ["Admin", "Underwriting"] },

                },
                {
                    path: 'Capturing/Managment',
                    name: 'CapturingManagment',
                    component: () => import('./Pages/Capturing/CapturingTable'),
                    meta: { authorize: ["Admin", "CarImageManager"] },

                },
                {
                    path: 'Capturing/TakePhoto',
                    name: 'TakePhoto',
                    component: () => import('./Pages/Capturing/CapturingTable'),
                    meta: { authorize: ["Admin", "CarImageManager"] },

                },
                {
                    path: 'Emergency/Notifications',
                    name: 'EmergencyNotifications',
                    component: () => import('./Pages/Emergency/EmergencyTable'),
                    meta: { authorize: ["Admin", "EmergencyNotifications"] },
                },
                {
                    path: 'Ardipedia',
                    name: 'ardipedia',
                    component: () => import('./Pages/Ardipedia/admin'),
                    meta: { authorize: ["Admin", "Ardipedia"] },
                },
                {
                    path: '/NotificationTemplate',
                    name: 'smsEmailTemplates',
                    component: () => import('./Pages/SmsEmailTemplates/Templates'),
                    meta: { authorize: ["Admin", "NotificationTemplates"] },
                },
                {
                    path: '/Personal/DataManagement',
                    name: 'dataManagement',
                    component: () => import('./Pages/Customers/PersonalDataManagment'),
                    meta: { authorize: ["Admin", "PersonalData"] },
                },
            ]
        },
        {
            path: '/Login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/ChangePassword',
            name: 'ChangePassword',
            component: () => import('./Pages/ChangePassword'),
        },
        {
            path: '/ChangePassword/:token',
            name: 'ChangePasswordById',
            component: () => import('./Pages/ChangePasswordByToken'),
        },

        // otherwise redirect to home
        {path: '*', redirect: '/'}
    ]
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const {authorize} = to.meta;
    const currentUser = authenticationService.currentUserValue;
    //console.log(currentUser)

    if (authorize) {
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return next({name: 'Login'});
        }
        /* if (!currentUser) {
             // not logged in so redirect to login page with the return url
             return next({ path: '/login', query: { returnUrl: to.path } });
         }*/
        const value = authorize.some(x => currentUser.userRole.some(y => y === x));
        // check if route is restricted by role
        if (authorize.length && !value) {

            alert("წვდომა არაა დაშვებული")

            // role not authorised so redirect to home page
            return next({path: '/'});
        }
    }

    next();
});

export default router